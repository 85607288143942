
    import { defineComponent } from 'vue';
    import Loader from '@/components/Loader.vue';
    import WeatherLocation from '@/components/WeatherLocation.vue';

    export default defineComponent({
        name: 'Weather',
        components: {
            Loader, WeatherLocation,
        },

        data() {
            return {
                loaded: false,
                outdoor: {
                    temperature: 0,
                    humidity: 0,
                    time: '0:00',
                    high: {
                        temperature: 0,
                        time: '0:00',
                    },
                    low: {
                        temperature: 0,
                        time: '0:00',
                    },
                },
                indoor: {
                    temperature: 0,
                    humidity: 0,
                    time: '0:00',
                    high: {
                        temperature: 0,
                        time: '0:00',
                    },
                    low: {
                        temperature: 0,
                        time: '0:00',
                    }
                },
            };
        },

        async created() {
            await this.fetchData({skipLoader: false});

            setInterval(async () => await this.fetchData({skipLoader: true}), 60000);
        },

        methods: {
            async fetchData({skipLoader}: {skipLoader: boolean}) {
                this.loaded = skipLoader
                    ? true
                    : false;

                const outdoor = await fetch('https://virtualwolf.org/rest/weather/locations/outdoor');
                const indoor = await fetch('https://virtualwolf.org/rest/weather/locations/indoor');

                const outdoorJson = await outdoor.json();
                const indoorJson = await indoor.json();

                this.outdoor = {
                    temperature: outdoorJson.temperature,
                    humidity: outdoorJson.humidity,
                    time: outdoorJson.timestamp,
                    high: {
                        temperature: outdoorJson.temperature_high,
                        time: outdoorJson.timestamp_high,
                    },
                    low: {
                        temperature: outdoorJson.temperature_low,
                        time: outdoorJson.timestamp_low,
                    }
                };
                this.indoor = {
                    temperature: indoorJson.temperature,
                    humidity: indoorJson.humidity,
                    time: indoorJson.timestamp,
                    high: {
                        temperature: indoorJson.temperature_high,
                        time: indoorJson.timestamp_high,
                    },
                    low: {
                        temperature: indoorJson.temperature_low,
                        time: indoorJson.timestamp_low,
                    }
                };

                this.loaded = true;
            }
        }
    });
