
    import { defineComponent, PropType } from 'vue';

    type Data = {
        time: String,
        temperature: String,
        humidity: String,
        high: {
            temperature: String,
            time: String,
        },
        low: {
            temperature: String,
            time: String,
        }
    }

    export default defineComponent({
        name: 'WeatherLocation',

        props: {
            name: String,
            data: Object as PropType<Data>,
        },
    });
